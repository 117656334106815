import Seo from 'src/components/seo/Seo'

import ProdutosEco from '../components/ProdutosEco'

function DecathlonItau() {
  return (
    <>
      <Seo title="Produtosecoconcepcao | Decathlon" />
      <ProdutosEco />
    </>
  )
}

export default DecathlonItau
